import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  getMissingParamsTypes,
  getMissingParamsTrackingValue
} from 'helpers/chatbot'
import { isProductSyncInProgress } from 'helpers/product'
import { LIMIT_KEYS } from 'constants/feature_limits'
import { CHATBOT_ENDPOINTS, SELLER_FEATURES_ENDPOINTS } from 'constants/chatbot'
import { BLOCKED_REQUEST_TYPES } from 'constants/chatbot/blockedRequests'
import { makeRequest } from 'services/api/helpers'
import { buildFullPathClean } from 'services/build_paths'
import { sendSafeSegmentTrackEvent } from 'services/segment'

import { useFeatureLimit } from 'hooks/feature_limits'

export const useEndpointValidation = endpointId => {
  const endpoint = CHATBOT_ENDPOINTS.find(e => e.id === endpointId)
  const isSellerFeature = endpoint
    ? SELLER_FEATURES_ENDPOINTS.find(e => e.id === endpoint.id)
    : false

  const { hasAccess: hasRequestAccess } = useFeatureLimit(
    LIMIT_KEYS.aiFeaturesChatRequests
  )

  const mwsSync = useSelector(state => state.account?.mwsSync)
  const selectedAccount = useSelector(
    state =>
      state.amazonSellerAccounts?.amazonSellerAccountSelector?.selectedAccount
  )

  const getBlockedRequestType = () => {
    const syncInProgress =
      isSellerFeature && isProductSyncInProgress(selectedAccount, mwsSync)

    switch (true) {
      case !hasRequestAccess:
        return BLOCKED_REQUEST_TYPES.REQUESTS_UPSELL
      case !endpoint:
        return BLOCKED_REQUEST_TYPES.ENDPOINT_NOT_FOUND
      case isSellerFeature && !selectedAccount:
        return BLOCKED_REQUEST_TYPES.NO_SELLER_ACCOUNT
      case isSellerFeature && syncInProgress:
        return BLOCKED_REQUEST_TYPES.SELLER_ACCOUNT_SYNCING
      default:
        return undefined
    }
  }

  const blockedRequestType = getBlockedRequestType()

  return {
    endpoint: blockedRequestType ? undefined : endpoint,
    blockedRequestType
  }
}

export const useAnswerRequestSpec = ({
  clientRequestSpec,
  enabled,
  createAnswer
}) => {
  const { endpoint, blockedRequestType } = useEndpointValidation(
    clientRequestSpec?.id
  )

  const [isLoading, setLoading] = useState(false)

  const sendRequest = async missingParamsValues => {
    if (!isLoading && enabled && endpoint) {
      setLoading(true)

      const params = {
        ...(endpoint?.defaultParams || {}),
        ...(clientRequestSpec?.params || {}),
        ...(missingParamsValues || {})
      }

      const method = endpoint.method || 'GET'
      const path =
        method === 'GET'
          ? buildFullPathClean(endpoint.path, params)
          : endpoint.path

      const response = await makeRequest(path, { method, data: params })

      const missingParamsTypes = getMissingParamsTypes(
        clientRequestSpec?.missingParams
      )

      missingParamsTypes.forEach(type => {
        const { value, finalType } = getMissingParamsTrackingValue(
          type,
          missingParamsValues
        )
        sendSafeSegmentTrackEvent('CTA Clicked', {
          text: 'AI Assist Chat - Missing Parameter',
          type: finalType,
          subtext: value
        })
      })

      createAnswer({
        clientRequestSpec: { id: endpoint?.id, params },
        payload: response?.data
      })

      setLoading(false)
    }
  }

  // If no missing params, load the request right away.
  useEffect(() => {
    const noMissingParams = !clientRequestSpec?.missingParams?.length
    if (noMissingParams) sendRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoading, blockedRequestType, sendRequest }
}
