import { useSelector } from 'react-redux'
import {
  NO_ACCESS_LIMIT,
  UNLIMITED_ACCESS_LIMIT
} from 'constants/feature_limits'
/*
  Hook that return data related to a feature limit.
  Only works for feature limits keys that are in the globalData.featureLimits object.
*/
export const useFeatureLimit = featureLimitKey => {
  const feature = useSelector(
    state => state.globalData?.featureLimits?.[featureLimitKey]
  )

  const featureLimit = feature?.featureLimit || 0
  const featureUsageCount = feature?.featureUsageCount || 0
  const intervalTimeframe = feature?.intervalTimeframe

  const hasAccess = featureLimit !== NO_ACCESS_LIMIT
  const isUnlimited = featureLimit === UNLIMITED_ACCESS_LIMIT
  const isLimitReached = !isUnlimited && featureUsageCount >= featureLimit
  const usageLeft = isUnlimited ? 'unlimited' : featureLimit - featureUsageCount

  return {
    featureLimitKey,
    hasAccess,
    isUnlimited,
    isLimitReached,
    usageLeft,
    featureLimit,
    featureUsageCount,
    intervalTimeframe
  }
}
