import { useSelector } from 'react-redux'
import {
  getSelectedAmazonSellerAccount,
  getSelectedCountryInfo
} from 'helpers/amazon_seller_account'

// Notion Documentation
// https://www.notion.so/junglescout/Selected-Amazon-Seller-Account-Hook-25e897e94a164fbfb2e254151d87a889

/**
 * Returns the selected Amazon Seller Account info
 * @returns {{amazon_seller_account_id?: number, marketplace_id?: number, country_code?: string, label?: string, hasSellerAccount: boolean}}
 */
export const useSelectedAmazonSellerAccount = () => {
  const selectedAccount = useSelector(
    state =>
      state.amazonSellerAccounts?.amazonSellerAccountSelector?.selectedAccount
  )
  const selectedCountry = useSelector(
    state =>
      state.amazonSellerAccounts?.amazonSellerAccountSelector?.selectedCountry
  )

  return getSelectedAmazonSellerAccount({
    amazonSellerAccounts: {
      amazonSellerAccountSelector: { selectedAccount, selectedCountry }
    }
  })
}

/**
 * Returns the selected country info
 * @returns {{marketplace_id?: number, country_code: string, is_default: boolean, review_request_count: number, review_request_delay: number, review_automation_enabled: boolean}}
 */
export const useSelectedCountry = () => {
  const selectedCountry = useSelector(
    state =>
      state.amazonSellerAccounts?.amazonSellerAccountSelector?.selectedCountry
  )

  return {
    ...(selectedCountry || {}),
    ...getSelectedCountryInfo(selectedCountry)
  }
}
